import { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { selectCurrentSlide } from '../data/decks/selectors'
import { updateSelectedProducts } from '../data/selections'
import { selectedProducts } from '../data/selections/selectors'
import { selectIsAdmin, selectOrganizationMarkup, selectUser } from '../data/user/selectors'
import { api } from '../services/api'

import { MultiSelectBar } from './MultiSelectBar'
import { BackButton } from './buttons/BackButton'
import ProductCardGrid from './productsView/ProductCardGrid'

// import { ReactComponent as EmptyState } from '../assets/svgs/empty_state.svg'
import { setCurrentList } from '../data/globals'
import { MenuGeneric } from './MenuGeneric'

const SORT_OPTIONS = [
  { id: 1, name: 'Recently added', value: 'updatedAt', desc: true },
  { id: 2, name: 'Cost - Low-High', value: 'cost', desc: false },
  { id: 3, name: 'Cost - High-Low', value: 'cost', desc: true },
  { id: 4, name: 'Inventory - High-Low', value: 'inventory', desc: true },
  { id: 5, name: 'Inventory - Low-High', value: 'inventory', desc: false },
]

export function MyListsTabDetail({ slot, onAddComplete }) {
  const alert = useAlert()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const user = useSelector(selectUser)
  const selected = useSelector(selectedProducts)
  const isAdmin = useSelector(selectIsAdmin)
  const favorites = useSelector((state) => state.favorites)
  const { currentSlide } = useSelector(selectCurrentSlide)
  const orgMarkup = useSelector(selectOrganizationMarkup)

  const currentList = useSelector((state) => state.globals.currentList)
  // const [currentList, setCurrentList] = useState(null)
  const [sort, setSort] = useState(SORT_OPTIONS[0])
  const [reload, setReload] = useState(false)
  const [sortedList, setSortedList] = useState([])

  const { listId } = params
  const { sortState } = location.state || {}

  useEffect(() => {
    if (sortState) {
      setSort(sortState)
    }
  }, [sortState])

  useEffect(() => {
    if (!listId) {
      setCurrentList(null)
      history.goBack()
    }
  }, [listId, history])

  function handleBack() {
    setCurrentList(null)
    history.goBack()
  }

  function onDelete(list) {
    setReload(!reload)
  }

  function handleSelect(data) {
    if (data.add) {
      updateSelectedProducts({
        ...selected,
        [data.id]: data.product,
        // [data.id]: {
        //   // we add a source here so we know which items to bulk delete
        //   // from the multi-select bar
        //   source: 'list',
        //   ...data.product,
        // },
      })
    } else {
      const updated = { ...selected }
      delete updated[data.id]
      updateSelectedProducts(updated)
    }
  }

  function handleSelectAll() {
    const newSelected = {}
    currentList.products?.forEach((p) => {
      newSelected[p.id] = p
    })
    updateSelectedProducts(newSelected)
  }

  function sortDateFunction(prop1, prop2, transform) {
    return {
      val1: transform ? new Date(prop1) : prop1,
      val2: transform ? new Date(prop2) : prop2,
    }
  }

  const fetchMyLists = useCallback(async () => {
    const { listId } = params
    if (!user.id || !listId) {
      return []
    }
    const { data } = await api.List.getById(listId)

    setCurrentList(data)
    const sorted = data?.products
      ? [...data.products].sort((a, b) => {
          const { val1, val2 } = sortDateFunction(
            a[sort.value],
            b[sort.value],
            sort.value === 'updatedAt',
          )
          if (sort.desc) {
            return val2 - val1
          }
          return val1 - val2
        })
      : []
    setSortedList(sorted)
    return data
  }, [user.id, sort])

  const { isLoading, error } = useQuery(['lists', sort, reload], fetchMyLists, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (error) {
      alert.error(error?.message ?? 'Error loading lists')
    }
  }, [error])

  function handleShowDetails(slot, id) {}

  function handleDeleteAllFromList() {
    setReload(!reload)
  }

  if (!currentList) {
    return null
  }

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="sticky top-0 z-10 flex items-center bg-white" style={{ minHeight: '54px' }}>
          <BackButton onClick={handleBack} text="Back to Lists" />
          <h3 className="ml-12">My Lists / {currentList.name}</h3>
          <div className="ml-auto">
            <MenuGeneric options={SORT_OPTIONS} value={sort} onChange={setSort} />
          </div>
        </div>
        <ProductCardGrid
          data={sortedList}
          selected={selected}
          onSelect={handleSelect}
          favorites={favorites}
          isAdmin={isAdmin}
          slot={slot}
          currentList={currentList}
          onAddComplete={onAddComplete}
          onShowDetails={handleShowDetails}
          currentSlide={currentSlide}
          orgMarkup={orgMarkup}
          onDelete={onDelete}
          sortParams={sort}
        />
      </div>
      <MultiSelectBar
        onSelectAll={handleSelectAll}
        user={user}
        onDeleteAllFromList={handleDeleteAllFromList}
      />
    </>
  )
}
