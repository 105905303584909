import { forwardRef } from 'react'

import { ProductCard } from '../ProductCard'

import { ProductSelectionType } from 'components/ProductsView'
import { IProduct } from 'types/modelTypes'

interface IProductCardGridProps {
  data: IProduct[]
  isAdmin?: boolean
  favorites?: any
  showFavorites?: boolean
  // onBulkAdd?: (id: string) => void
  onBrandClick?: (brand: string) => void
  onSelect: (props: ProductSelectionType) => void
  selected?: any
  page: number
  slot?: number | null
  onAddComplete: () => void
  onShowDetails?: (slot: number, id: number) => void
  currentSlide?: any
  orgMarkup?: any
  currentList?: any
  sortParams?: any
  onDelete?: () => void
  fromNotes?: boolean
}
const ProductCardGrid = forwardRef<HTMLUListElement, IProductCardGridProps>(
  (
    {
      data,
      isAdmin,
      favorites,
      showFavorites,
      // onBulkAdd,
      onBrandClick,
      onSelect,
      selected,
      page,
      slot = null,
      onAddComplete,
      currentSlide,
      orgMarkup,
      currentList,
      onDelete,
      sortParams,
      fromNotes,
    },
    ref,
  ) => {
    return (
      <ul
        role="list"
        ref={ref}
        className="scrollbar grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll px-4 pb-6 pt-2 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5"
      >
        {data?.map((prod, i) => (
          <ProductCard
            key={`${prod.id}_${i}`}
            id={`restore-${prod.id}`}
            isAdmin={isAdmin}
            product={prod}
            favorites={favorites}
            showFavorites={showFavorites}
            // onBulkAdd={onBulkAdd}
            onBrandClick={onBrandClick}
            onSelect={onSelect}
            page={page}
            isSelected={selected[prod.id] !== undefined}
            slot={slot}
            onAddComplete={onAddComplete}
            currentSlide={currentSlide}
            orgMarkup={orgMarkup}
            currentList={currentList}
            onDelete={onDelete}
            sortParams={sortParams}
            fromNotes={fromNotes}
          />
        ))}
      </ul>
    )
  },
)

ProductCardGrid.displayName = 'ProductCardGrid'

export default ProductCardGrid
