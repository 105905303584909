export type UserStatus = 'active' | 'inactive' | 'invited'
export type UserRoles = 'admin' | 'user'
export type DirectoryType = 'file' | 'folder'
export type DirectoryLevel = 'user' | 'client' | 'sub_1' | 'sub_2'

export interface IUser {
  id: number
  name: string
  uuid: string
  email: string
  status: UserStatus
  role: UserRoles
  masterAccess: boolean
  masters: any[]
  lists: any[]
  favorites: any[]
  directory: any
  organization: IOrganization
  organizationId: number
  accessToken: string
}

export interface IDirectory {
  name: string
  type: DirectoryType
  level: DirectoryLevel
  parentId: number | null
  children: IDirectory[]
}

export enum ContinuityType {
  single = 'single',
  quad = 'quad',
  quad_horizontal = 'quad_horizontal',
  quint = 'quint',
  title = 'title',
}

export interface IContinuity {
  id: string | number | null
  uuid: string | null
  priceModifier: number | string
  priceModified?: boolean
  title: string | null
  name: string
  price: number
  altImages: Record<string, any>
  userId: string
  type: ContinuityType
  thumbnail: string | null
  layout: string
  layoutModified?: boolean
  backgroundImage: string | null
  overlayImage: string | null
  position: number
  createdAt: Date | string
  updatedAt: Date | string
  continuityMaster?: number
  directoryId?: number | null
  master?: IContinuityMaster
  products: IProduct[]
}

export interface IContinuityMaster {
  id: number | null
  recipient: string | null
  type: string
  filename: string
  title: string | null
  continuities: IContinuity[]
  client?: IClient
  directory?: IDirectory | null
  user?: IUser
}

export interface IProduct {
  id: number | string
  productId: string
  uuid: string
  manufacturerId: string | null
  itemNumber: string | null
  name: string
  computedProductTitle: string | null
  description: string | null
  brand: string | null
  inventory: number
  colors: string | null
  cost: number
  price: number
  msrp: number
  computedPrimaryImage: string
  transparentImage: string | null
  primaryImage: string | null
  lifestyleImage: string | null
  packagingImage: string | null
  brandLogoImage: string | null
  vendor: string | null
  activeStatus: string
  tags: string | null
  category: string | null
  subCategory: string | null
  subCategory2: string | null
  subCategory3: string | null
  subCategory4: string | null
  packagingType: string | null
  productHeight: number | null
  productWidth: number | null
  productLength: number | null
  productDimensionUnit: string | null
  parentId: string | null
  collections: string[]
  imageAssets: string[]
  inProductionEta: string | null
  inProductionQuantity: number | null
  fobZipCode: string | null
  masterCartonWeight: number | null
  masterCartonWeightUnit: string | null
  masterCartonHeight: number | null
  masterCartonWidth: number | null
  masterCartonLength: number | null
  masterCartonDimensionalUnit: string | null
  masterCartonQuantity: number | null
  exclusive: boolean
  warehouse: boolean
  dropship: boolean
  material: string | null
  incomingInventory1: number | null
  incomingInventory1Date: string | null
  incomingInventory2: number | null
  incomingInventory2Date: string | null
  tier1: number | null
  tier1Qty: number | null
  tier2: number | null
  tier2Qty: number | null
  tier3: number | null
  tier3Qty: number | null
  tier4: number | null
  tier4Qty: number | null
  tier5: number | null
  tier5Qty: number | null
  leadTime: string | null
  homepageSlot: number | null
  handling: number | null
  freightCost: number | null
  specSheet: string | null
  vendorIdPV2: string | null
  salsifyCreatedAt: Date
  salsifyUpdatedAt: Date
  createdAt: Date
  updatedAt: Date
  listId: null
}

export interface IList {
  id: number
  name: string
  products?: IProduct[]
  user?: IUser[]
}

export interface IClient {
  id: number
  name: string
  masters: IDeck[]
}

export interface IDeck {
  recipient: string | null
  type: string
  filename: string
  title: string | null
  contiuities?: IContinuity[]
  client?: IClient
  directory?: IDirectory
  user?: IUser
}

export interface IGalleryItem {
  id: number
  name: string
  key: string
  url: string
}

export interface IOrganization {
  id: number
  name: string
  exclusivesOnly: boolean
  logoUrl: string
  markup: number
  tier1: number
  tier2: number
  tier3: number
  tier4: number
  tier5: number
  allAccess: boolean
  members?: IUser[]
  products?: IProduct[]
}
