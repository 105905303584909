import { GenericModal } from '../modals/GenericModal'
import { BackgroundImageTabs } from './BackgroundImageTabs'

import './masonry.css'

export default function BackgroundImageModal({ show, onClose, onSelect }) {
  return (
    <GenericModal
      title="Select Photo"
      show={show}
      onClose={onClose}
      showButtons={false}
      fullWidth={true}
    >
      <div className="flex-flex-col">
        <BackgroundImageTabs onSelect={onSelect} />
      </div>
    </GenericModal>
  )
}
