import { TrashIcon } from '@heroicons/react/solid'
import { Storage } from 'aws-amplify'
import throttle from 'lodash.throttle'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import Masonry from 'react-masonry-css'

import { api } from '../../services/api'

import { ImageUploadButton } from '../buttons/ImageUploadButton'
import { SearchInput } from '../inputs/SearchInput'

import './masonry.css'

export default function BackgroundImageModalPassportTab({ onSelect }) {
  const alert = useAlert()

  const [images, setImages] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const fetchGallery = async () => {
    try {
      const { data } = await api.Gallery.getAll({ searchTerm })
      setImages(data)
    } catch (error) {
      alert.error(error)
    }
  }

  useEffect(() => {
    fetchGallery()
  }, [searchTerm])

  function handleDelete() {
    fetchGallery()
  }

  function handleSearch(val) {
    setSearchTerm(val)
  }

  const throttledSearch = throttle(handleSearch, 1000)

  async function handleUploadComplete({ key, name }) {
    try {
      const url = `${process.env.REACT_APP_BUCKET_BASE_URL}/public/${key}`
      const { data } = await api.Gallery.create({ key, name, url })
      setImages([...images, data])
    } catch (error) {
      alert.error(error)
    }
  }
  return (
    <div className="flex-flex-col">
      <div className="flex justify-between">
        <div className="w-3/4 md:max-w-1/2">
          <SearchInput onChange={throttledSearch} />
        </div>
        <ImageUploadButton onUploadComplete={handleUploadComplete} />
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, idx) => (
          <BackgroundImageItem
            key={idx}
            image={image}
            onSelect={onSelect}
            onDelete={handleDelete}
          />
        ))}
      </Masonry>
    </div>
  )
}

const BackgroundImageItem = ({ image, onSelect, onDelete }) => {
  const alert = useAlert()
  const [hoverActive, setHoverActive] = useState(false)

  function handleSelect(img) {
    onSelect(img)
  }

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  async function handleDelete(image) {
    try {
      const { data } = await api.Gallery.delete({ key: image.key })
      await Storage.remove(image.key)
      onDelete(data)
    } catch (error) {
      alert.error(error.message ? error.message : JSON.stringify(error))
    }
  }
  return (
    <div
      key={image.id}
      className="relative mb-6 w-full bg-white"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img
        onClick={() => handleSelect(image)}
        className="cursor-pointer object-fill"
        alt={image.key}
        src={image.url}
        // src="https://source.unsplash.com/collection/1163637/580x480"
      />
      {/* <span className="break-words">{image.name}</span> */}
      {hoverActive && (
        <TrashIcon
          onClick={() => handleDelete(image)}
          className="absolute right-2 top-2 z-10 h-5 w-5 cursor-pointer text-red-500 hover:text-red-700"
        />
      )}
    </div>
  )
}
