import { EyeIcon, EyeOffIcon, RefreshIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { captureSlide, classNames } from '../../utils/helpers'

import { updateCurrentSlide } from '../../data/decks'

import BackgroundImageModal from './BackgroundImageModal'

export default function ProductSelectorBackground({ slide, children }) {
  const { ref } = useResizeObserver({
    onResize: ({ width, height }) => {
      setHeight(height)
    },
  })

  const [height, setHeight] = useState()
  const [highlightBackground, setHighlightBackground] = useState(false)
  const [showGallery, setShowGallery] = useState(false)
  const [showOverlayGallery, setShowOverlayGallery] = useState(false)
  const [background, setBackground] = useState(null)
  const [overlayVisible, setOverlayVisible] = useState(true)

  useEffect(() => {
    setBackground({ url: slide.backgroundImage })
  }, [slide])

  useEffect(() => {
    captureSlide()
  }, [overlayVisible])

  function setAsBackground(img) {
    setBackground(img)
    updateCurrentSlide({
      backgroundImage: img.url,
    })
    captureSlide()
  }

  function setAsOverlay(img) {
    updateCurrentSlide({
      overlayImage: img.url,
    })
    captureSlide()
  }

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          'relative h-full cursor-pointer bg-cover bg-center bg-no-repeat',
          highlightBackground ? ' border-2 border-blue-500' : 'hover:bg-gray-200',
        )}
        style={{
          // height: BUIDLER_DIMENSIONS.height + 'px',
          // width: BUIDLER_DIMENSIONS.height + 'px',
          width: height + 'px',
          minWidth: height + 'px',
          // height: slide.layout === 'bordered' || slide.layout === 'background' ? '90%' : '100%',
          // paddingBottom: '56.25%',
          // minWidth: BUIDLER_DIMENSIONS.height + 'px',
          // padding: slide.layout === 'bordered' || slide.layout === 'background' ? '20px' : '',
          backgroundImage:
            slide.layout === 'background'
              ? `url(${
                  background?.url ??
                  'https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75'
                })`
              : '',
        }}
        onClick={(e) => {
          if (slide.layout === 'background') {
            setHighlightBackground(!highlightBackground)
          }
        }}
      >
        <div
          className="h-full w-full"
          style={{
            padding: slide.layout === 'bordered' || slide.layout === 'background' ? '5%' : 0,
          }}
        >
          {children}
          {highlightBackground && (
            <>
              <span
                style={{ top: '-6px', left: '-6px' }}
                className="absolute z-40 h-3 w-3 rounded-full bg-white shadow-full"
              />
              <span
                style={{ top: '-6px', right: '-6px' }}
                className="absolute z-40 h-3 w-3 rounded-full bg-white shadow-full"
              />
              <span
                style={{ bottom: '-6px', left: '-6px' }}
                className="absolute z-40 h-3 w-3 rounded-full bg-white shadow-full"
              />
              <span
                style={{ bottom: '-6px', right: '-6px' }}
                className="absolute z-40 h-3 w-3 rounded-full bg-white shadow-full"
              />
              {slide.layout === 'background' && (
                <div
                  style={{ bottom: '-54px', left: 0, right: 0 }}
                  className="absolute z-10 flex justify-center"
                >
                  <button
                    className="flex flex-col items-center rounded px-20 py-1 text-gray-500 shadow-full-light hover:text-gray-400"
                    onClick={() => setShowGallery(true)}
                  >
                    <RefreshIcon className="h-5 w-5" />
                    <span className="text-3xs">Replace photo</span>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {slide.layout === 'overlay' ? (
          <>
            <div>
              {overlayVisible && slide.overlayImage ? (
                <div className="absolute inset-0 z-10 h-full w-full">
                  <img
                    src={
                      slide.overlayImage
                      // 'https://images.unsplash.com/photo-1612729664428-f2aa76606603?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80'
                    }
                    className="h-full w-full object-cover"
                  />
                </div>
              ) : null}
            </div>
            <div
              style={{ bottom: '-54px', left: 0, right: 0 }}
              className="absolute z-10 flex justify-center"
            >
              <div className="flex w-[350px] items-center justify-between rounded px-20 py-1 text-gray-500 shadow-full-light">
                <button
                  onClick={() => setShowOverlayGallery(true)}
                  className="mr-4 flex flex-col items-center hover:text-gray-400"
                >
                  <RefreshIcon className="h-5 w-5" />
                  <span className="text-3xs">Replace photo</span>
                </button>
                <div className="mr-4 flex flex-col items-center hover:text-gray-400">
                  {overlayVisible ? (
                    <EyeOffIcon
                      onClick={() => setOverlayVisible(false)}
                      className="h-5 w-5 cursor-pointer"
                    />
                  ) : (
                    <EyeIcon
                      onClick={() => setOverlayVisible(true)}
                      className="h-5 w-5 cursor-pointer"
                    />
                  )}
                  <span className="text-3xs">Toggle Overlay</span>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <BackgroundImageModal
        onClose={() => setShowGallery(false)}
        onSelect={(img) => {
          setAsBackground(img)
          setShowGallery(false)
        }}
        show={showGallery}
      />
      <BackgroundImageModal
        onClose={() => setShowOverlayGallery(false)}
        onSelect={(img) => {
          setAsOverlay(img)
          setShowOverlayGallery(false)
        }}
        show={showOverlayGallery}
      />
    </>
  )
}
